import React, { useState } from "react";
import Layout from "../../components/Layout";

import { setItemToLStore } from "../../helpers/hash";
import { navigate } from "gatsby";

import { Link } from "gatsby";

import fetch from "node-fetch";

import "../../styles/global.css";
import "../../styles/fonts.css";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const submitForm = (e) => {
  e.preventDefault();
  var formData = new FormData(e.currentTarget);

  let obj = { "form-name": "claim-listing" };

  for (var value of formData.entries()) {
    obj[value[0]] = value[1];
    // myArray.push(obj);
  }

  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: encode({
      ...obj,
      "landing-url":
        typeof window !== "undefined" &&
        window.localStorage &&
        window.localStorage.getItem("landingpath")
          ? window.localStorage.getItem("landingpath")
          : "Not Provided",
      "current-url":
        typeof window !== "undefined" &&
        window.localStorage &&
        window.localStorage.getItem("currentpath")
          ? window.localStorage.getItem("currentpath")
          : "Not Provided",
    }),
  })
    .then(() => {
      setItemToLStore("form", { date: new Date(), ...obj });
      //after submit success, show spinner .05 sec
      setTimeout(() => navigate("/claim/success"), 500);
    })
    .catch((error) => alert(error));
};

const Claim = (props) => {
  console.log("props", props);
  const SocialLink = ({ type, text }) => {
    return (
      props.serverData[type] !== undefined &&
      props.serverData[type] !== null && (
        <div className="flex gap-2">
          <input type="checkbox" field={"valid_" + type} defaultChecked />
          <a href={props.serverData[type]} rel="nofollow ugc" target="_blank">
            {text}
          </a>
        </div>
      )
    );
  };
  const [pageRoute, updatePageRoute] = useState(0);

  if (props.serverData == null) {
    return (
      <div className="max-w-2xl mx-auto space-y-2">
        <h2 className="text-2xl font-bold">
          Sorry, no such business exists in out database.
        </h2>
        <p>Check the link and make sure there is no typo.</p>
        <div>
          Click{" "}
          <Link
            href="/get-listed"
            className="text-blue-600 hover:text-blue-800"
          >
            this link to get listed.
          </Link>
        </div>
      </div>
    );
  }
  if (pageRoute == 2) {
    return (
      <div className="max-w-2xl mx-auto space-y-2">
        <h2 className="text-2xl font-bold p-2 ">Successfully Submitted</h2>
        <div className="bg-blue-200 border-blue-300 rouned-2xl p-2 -mx-2"></div>
      </div>
    );
  }
  if (pageRoute == 1) {
    return (
      <div className="max-w-2xl mx-auto space-y-2">
        <div
          className="font-bold hover:text-blue-800 cursor-pointer"
          onClick={(_) => updatePageRoute(0)}
        >
          {"<--"}Go back
        </div>
        <h2 className="text-2xl font-bold">Claim Details</h2>
        <form
          name="claim-listing"
          method="post"
          action="/claim/success/"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={(e) => submitForm(e)}
          data-netlify={true}
        >
          <div>
            <label className="block">Email</label>
            <input type="text" required />
          </div>
          <div>
            <label className="block">Phone To Contact</label>
            <input type="tel" />
          </div>
          <div>
            <label className="block">Proof Of Ownership</label>
            <div className="p-2 text-blue-700 font-bold">
              Max file upload size: 2MB.
            </div>
            <input type="file" />
          </div>
          <button
            className="bg-blue-600 text-white p-3 rounded-2xl hover:bg-blue-500"
            onClick={(_) => updatePageRoute(2)}
          >
            Submit
          </button>
        </form>
      </div>
    );
  }
  return (
    <div className="max-w-2xl mx-auto space-y-2">
      <h1 className="font-bold">Claim Your Business</h1>
      <div className="-mx-4 p-4 bg-blue-200 text-blue-800 my-2 rounded-2xl">
        Does the following information look correct?
      </div>
      <h2 className="text-2xl font-bold">{props.serverData.Name}</h2>
      <div className="space-y-2">
        <div>{props.serverData.Description}</div>
        <div>{props.serverData.phone}</div>
        <div>{props.serverData.url}</div>
        <div>{props.serverData.Address}</div>
        <div>
          {props.serverData.City}, {props.serverData.State}{" "}
          {props.serverData.ZIP}
        </div>
        <div className="font-bold">Other Profiles</div>
        <span>
          Click the platform name to validate links—uncheck them when they are
          invalid
        </span>
        <div className="flex gap-2">
          <SocialLink type="yelp" text="Yelp" />
          <SocialLink type="angis" text="Angis" />
          <SocialLink type="bbb" text="BBB" />
          <SocialLink type="homeadvisor" text="HomeAdvisor" />
          <SocialLink type="guildQuality" text="GuildQuality" />
          <SocialLink type="thumbtack" text="Thumbtack" />
        </div>
      </div>
      <button
        className="bg-blue-600 text-white p-3 rounded-2xl hover:bg-blue-500"
        onClick={(_) => updatePageRoute(1)}
      >
        Look's Good
      </button>
      <button className="p-3">Update</button>
    </div>
  );
};

export async function getServerData({ params }) {
  try {
    const res = await fetch(
      `https://api.localtier.com/businesses?Slug=` + params.slug.split("/")[0]
    );
    if (!res.ok) {
      throw new Error(`Response failed`);
    }
    const resList = await res.json();
    let business = {};
    if (resList.length > 0) {
      business = resList[0];
      delete business.email;
    } else {
      business = null;
    }
    return {
      props: business,
    };
  } catch (error) {
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default Claim;
